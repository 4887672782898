$Main: #ed1c24;
$Alt: #00a99d;
$MainTr: #ed1c2440;
$AltTr: #00a99d40;

$hl: #ffee00;

$B: black;

$DGray: #333;
$Gray: #545454;
$LGray: #8c979e;

$W: white;

::-moz-selection { /* Code for Firefox */
  background: rgba($hl, .8);
}

::selection {
  background: rgba($hl, .8);
}

body {
	// font-family: 'Noto Sans', sans-serif;
	font-family: 'Poppins', sans-serif;
	font-size: #{'min(72px,20vmin)'};
	font-weight: 400;
	color: $Gray;
	text-align: center;
}

h1 {
	font-size: 100%;
	line-height: 110%;
	font-weight: 700;
}

h2 {
	color: $LGray;
	font-size: 70%;
	line-height: 120%;
	font-weight: 700;
}

h3 {
	font-size: 55%;
	line-height: 115%;
	text-transform: uppercase;
	font-weight: 700;
}

h4 {
	font-size: 36%;
	line-height: 125%;
	font-weight: 700;
}

h5 {
	font-size: 28%;
	line-height: 125%;
	font-weight: 400;
}

p {
	font-size: 25%;
	line-height: 110%;
	max-width: 75ch;
	margin: auto;
}

a, li {
	color: $Alt;
	transition: color .3s;
	&:hover, &:focus {
		color: $Main;
	}
}

h1, h2, h3, h4, h5, p, a {
	margin: 15px auto;
}

ul {
	list-style: disc outside;
	text-align: left;
	li {
		margin-bottom: 12px;
		a {
			width: 100%;
			display: block;
		}
	}
}

.disabled {
	color: $Gray;
	pointer-events: none;
	* {
		color: $Gray;
	}
}

picture, img {
	display: block;
	width: 100%;
}

form {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(#{'min(220px,100%)'}, 1fr));
	max-width: #{'min(900px, 100%)'};
	grid-gap: 12px;
	margin: 15px auto 0;
	text-align: center;
	input {
		display: block;
		width: 100%;
		border: 2px solid $Alt;
		border-radius: 10px;
		font-size: 25%;
		font-weight: 600;
		padding: 5px 10px;
		box-sizing: border-box;
		transform: scale(1);
		transform-origin: 50% 360%;
		box-shadow: 0 0px 0px rgba(0,0,0,0);
		transition: border .3s ease, bottom .3s ease, box-shadow .3s ease, transform .3s ease;
		&.doubleInput {
			width: calc(200% + 12px);
			max-width: calc(100vw - 100px);
		}
		&:active, &:focus {
			border-color: $Main;
			transform: scale(1.05);
			box-shadow: 0 2px 8px rgba(0,0,0,.4);
		}
	// }
	}
	button {
		justify-self: center;
	}
}

.hidden {
	display: none;
}

.article {
	text-align: left;
	h1, h2, h3, h4, h5, p, a {
		margin: 0 0 12px;
	}
}

.redBG {
	color: $W;
	background-color: $Main;
}

.greenBG {
	color: $W;
	background-color: $Alt;
	::-moz-selection { /* Code for Firefox */
	  background: rgba($Main, .6);
	}

	::selection {
	  background: rgba($Main, .6);
	}
}

button {
	color: $W;
	font-weight: 700;
	padding: 0 24px;
	height: 48px;
	border: 2px solid;
	font-size: 25%;
	border-radius: 10px;
	display: block;
	margin: 10px auto 0;
	box-sizing: border-box;
	width: 200px;
	max-width: 100%;
	cursor: pointer;
	transform: scale(1);
	transition: transform .3s ease, background-color .3s, border .3s ease, box-shadow .3s ease;

	&.leftBtn {
		margin-left: 0;
	}
	&.redBtn {
		background-color: $Main;
		border-color: $Main;
		box-shadow: 0 3px 15px $MainTr;
		&.outlineBtn {
			background-color: $MainTr;
		}
		&:hover {
			box-shadow: 0 5px 25px $MainTr;
		}
		&:active {
			box-shadow: 0 2px 10px $MainTr;
		}
	}
	&.greenBtn {
		background-color: $Alt;
		border-color: $Alt;
		box-shadow: 0 3px 15px $AltTr;
		&.outlineBtn {
			background-color: $AltTr;
		}
		&:hover {
			box-shadow: 0 5px 25px $AltTr;
		}
		&:active {
			box-shadow: 0 2px 10px $AltTr;
		}
	}
	&:hover, &:focus {
		transform: scale(1.08);
	}
	&:active {
		transform: scale(.93);
	}
}

.buttonCont{
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	box-sizing: border-box;
	flex-wrap: wrap;
	button {
		display: inline-block;
		margin: 10px 15px;
	}
}

header {
	display: flex;
	justify-content: center;
	align-items: center;
	background: url(../media/header-still.jpg) center center;
	background-size: cover;
	color: $W;
	background-color: $Gray;
	text-shadow: 0 2px 12px rgba(0,0,0,.9);
	min-height: 50vmin;
	max-height: 640px;
	padding: 25px;
	position: relative;
	overflow: hidden;
	transition: min-height .3s ease, max-height .3s ease;
	h1, p {
		transition: height .3s ease, margin .3s ease;
	}
	&.smaller {
		min-height: 25vmin;
		max-height: 480px;
		p {
			height: 0;
			margin: 0;
			overflow: hidden;
		}
		video {
			display: none;
		}
	}
	#videoCont {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0 auto;
		height: 100%;
		width: 100%;
		z-index: 1;
		video {
			width: 100vw;
			min-width: 1138px;
		}
	}
	#identCont {
		display: block;
		position: relative;
		text-align: center;
		z-index: 2;
		font-weight: 700;
		#logoCont {
			cursor: pointer;
			min-width: 240px;
			width: 50vw;
			max-width: 360px;
			margin: 0 auto 20px;
			transform: scale(1);
			-webkit-filter: drop-shadow( 0 2px 10px rgba(0, 0, 0, .5));
			filter: drop-shadow( 0 2px 10px rgba(0, 0, 0, .5));
			transition: transform .3s ease;
			&:hover {
				transform: scale(1.08);
			}
			&:active {
				transform: scale(.93);
			}
		}
	}
}

main {
	width: 100%;
	>div {
		width: 100%;
		display: block;
		// The padding below uses max and calc to derive the width the padding should have based on the width of the window and the hard-coded desired maximum width of the element, indicated below.
		//
		//                                       vvvvvv = max-width
		padding: 50px #{'max(min(50px, 10vw), calc((100vw - 1000px) / 2))'};
		box-sizing: border-box;
		>*:first-child {
			margin-top: 0;
		}
		>*:last-child{
			margin-bottom: 0;
		}
	}
}

#prgbar {
	position: relative;
	width: 0;
	height: 12px;
	background: $Main;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
}

#prefooter, footer {
	box-sizing: border-box;
	padding: 50px;
	width: 100%;
	text-align: center;
}

footer {
	color: $W;
	background-color: $DGray;
	h5, p {
		text-align: center;
		font-weight: 600;
	}
}
